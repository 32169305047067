<template>
  <el-container class="container">
    <el-main class="main">
      <template>
        <iframe
          :src="pathUrl"
          frameborder="1"
          allowfullscreen="true"
          width="100%"
          ref="leftFrame"
          height="99%"
          seamless
          sandbox="allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups"
        >

        </iframe>
      </template>
    </el-main>
    <el-footer></el-footer>
  </el-container>
</template>

<script>

  export default {
    name: "previewPythonfile",
    data () {
      return {
        pathUrl: '',
      }
    },
    created () {
      let code = this.$route.query.code.replace(/↵/g, '<br/>').replace(/\\n/g, '<br/>')
      this.pathUrl = process.env.VUE_APP_PYTHON_IDE+ "?pagetype=teacher&pagesource=submit"

      setTimeout(()=> {
        this.$refs.leftFrame.contentWindow.postMessage({
          code:code
        }, '*')
      },800)
    },
  }
</script>

<style lang="scss" scoped>
  .container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background-color: #e5e5e5;
    height: 100%;
    padding: 0 30px;
    .header {
      display: flex;
      align-items: center;
      box-sizing: border-box;
    }
    .main {
      background: #fff;
      overflow: auto;
      padding: 0;
      .content {
        min-width: 992px;
        max-width: 1400px;
        margin: 0 auto 0;
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: justify;
        color: #616161;
      }
    }
  }

  /deep/ .el-page-header__left:hover {
    color: #f5c319;
  }
</style>
