var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    { staticClass: "container" },
    [
      _c(
        "el-main",
        { staticClass: "main" },
        [
          [
            _c("iframe", {
              ref: "leftFrame",
              attrs: {
                src: _vm.pathUrl,
                frameborder: "1",
                allowfullscreen: "true",
                width: "100%",
                height: "99%",
                seamless: "",
                sandbox:
                  "allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups",
              },
            }),
          ],
        ],
        2
      ),
      _c("el-footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }